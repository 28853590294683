<template>
     <div class="p-1">
   <div class="row">
       <div class="col-12 col-md-12">
            <div class="row">
              <div class="col-sm-12">
                <input type="text" placeholder="  Start your question with What, How, Why, etc." class="form-control" id="question">
              </div>
            </div>
             <div class="mt-3">
                <textarea placeholder="Optionally add more info or give a context to your question." rows="8" class="form-control" id="publish_question"></textarea>
             </div>
             <div class="form-check">
               <input class="form-check-input" type="checkbox" value="" id="not_show_name">
               <label class="form-check-label" for="not_show_name">
                 Do not show my name
               </label>
             </div>
             <div class="my-4 text-center">
                <button type="button" class="btn btn-color text-white">Cancel</button>
                <span class="ms-3"><button type="button" class="btn btn-blue-color text-white">Publish</button></span>
             </div>
       </div>
   </div>
 </div>
</template>
<script lang="js" src='./publish-thoughts.js'></script>