<template>
    <div class="page-content w-100">
       <!-- start heading -->
   <div class="row">
  <div class="col-sm-12">
     <p class="text-center page-heading">Publish</p>
   </div>

 </div>
    <!-- end heading -->
  <div class="row mt-2 ">
  <div class="col-sm-12 mx-auto">

      <!-- start accordion -->
         <div class="accordion box-shadow" id="accordionPublish">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseQuestions" aria-expanded="true" aria-controls="collapseQuestions">
        <strong>A question to the community</strong>
      </button>
    </h2>
    <div id="collapseQuestions" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionPublish">
      <div class="accordion-body p-4">
           <publishQuestion></publishQuestion>
      </div>
    </div>
  </div>
  <!-- end first -->
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThought" aria-expanded="false" aria-controls="collapseThought">
       <strong>My thoughts</strong>
      </button>
    </h2>
    <div id="collapseThought" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionPublish">
      <div class="accordion-body p-4">
          <publishThoughts></publishThoughts>
      </div>
    </div>
  </div>
  <!-- end second -->
</div>

      <!-- end accordion -->

  </div>
  </div>
 </div>

</template>
<script lang="js" src='./publish.js'></script>
<style>
  .col-sm-12.mx-auto{
    margin-bottom: 30px;
  }
</style>
